@import 'utils/styles';

body {
	background-color: $color-background;
	color: white;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	-moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100%;
}

.App {
	display: flex;
	flex-direction: column;

	header, footer {
		flex: initial;
	}

	section {
		margin-bottom: auto;
		overflow: auto;
	}
}

.key-hint {
	font-size: 0.7em;
	font-weight: lighter;
}

button {
	cursor: pointer;
	user-select: none;
}

input,
button {
	background-color: transparent;
	border: none;
	color: white;
	outline: none;
}

button:focus {
	background-color: $color-positive;
}

.icon-status,
.icon-depict {
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translate(0, -50%);
	width: 20px;
}

.icon-status {
	left: 10%;
}

.icon-depict {
	right: 10%;
}

.late {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	50% {
		background-color: $color-very-negative;
	}
}
