$color-background: #0d0c0f;

$color-dark: #242329;
$color-dark-transparent: rgba(36, 35, 41, 0.8);
$color-neutral: #2d2b33;
$color-light: #413f47;
$color-positive: #3f4741;
$color-negative: #472c2a;
$color-very-negative: #ac0b00;


$color-program: #970000;
$color-action: #000097;
$color-preview: #009700;
$color-white: #fff;

$button-width: 1fr;
$button-height: 60px;
$center-width: 2fr;

$clock-height: 100px;
$gutter: 20px;
