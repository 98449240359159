@import '../utils/styles';
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.timeline {
	padding: $clock-height + $gutter 0;
	overflow-x: hidden;
	overflow-y: scroll;
	list-style-type: none;
	margin: 0;
	user-select: none;
}

.item {
	display: flex;
	border-bottom: 1px solid $color-light;
	align-items: center;

	.item-status {
		width: 120px;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'VT323', monospace;
		font-size: 1.3em;
	}

	.item-image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		cursor: help;
		color: inherit;
		text-decoration: none;

		&:hover {
			color: $color-preview;
		}
	}

	.message {
		padding: $gutter/2 $gutter $gutter/2 0;
		flex: 1;
	}

	&.late {
		background-color: $color-negative;
	}

	&.completed {
		background-color: $color-positive;
	}

	&:first-child {
		border-top: 1px solid $color-light;
	}
}

.up-next {
	font-size: 4vw;
}
