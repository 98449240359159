@import '../utils/styles';
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.clock {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: $clock-height;
	font-size: 5em;
	font-family: 'VT323', monospace;
	background-color: $color-dark-transparent;
	color: $color-white;
	text-align: center;
	border-bottom: 1px solid $color-light;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	&.late {
		background-color: $color-negative;
	}
}
